:root {
  --loading-factor: 0.35;
  --swiper-navigation-size: 24px !important;
}

/* Change autocomplete styles in WebKit */
:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  box-shadow: 0 0 0px 1000px #fff inset !important;
  border-radius: 0px !important;
}

#root {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Golos Text', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.animate-scale {
  animation: infinite-scale 2.5s ease-in-out infinite;
}

@keyframes infinite-scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

.swiper-wrapper {
  align-items: center;
}

.swiper-pagination {
  position: static !important;
  margin-top: 20px;
}

.swiper-button-prev,
.swiper-button-next {
  padding: 0px 20px;
}




.arrow {
  position: relative;
  bottom: -2rem;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}